import {
  AddressText,
  DateText,
  TopHeaderMenuRoot,
} from '@composites/TopHeaderMenu/styles';
import { usePublicCoLocatedEventsByEventId } from '@utils/apiHooks';
import { counterDate, eventTimeFormat } from '@utils/time';
import { getLanguage, getMode } from '../../store/slices/configSlice';
import { EventMenuRoot, EventTopMenuBody, NavBarRoot } from './styles';

import Icon from '@components/Icon';
import { RowAlignedItems } from '@components/LinearLayout';
import EventMenuDesktop from '@composites/EventMenuDesktop';
import EventMenuMobile from '@composites/EventMenuMobile';
import HeaderBottomComponent from '@composites/TopHeaderMenu/HeaderBottomComponent';
import HeaderTopComponent from '@composites/TopHeaderMenu/HeaderTopComponent';
import { getEventDetails } from '@store/slices/eventSlice';
import { MODE } from '@utils/constants';
import { useTabletWidth } from '@utils/hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

export const GlobalNav = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mode = useSelector(getMode);
  const language = useSelector(getLanguage);
  const isTablet = useTabletWidth();
  const sluggedEvent = useSelector(getEventDetails)?.[0];
  const { data: coLocatedEvents, isLoading: isCoLocatedEventsLoading } =
    usePublicCoLocatedEventsByEventId({
      eventId: sluggedEvent?.id,
      enabled: sluggedEvent?.id?.length > 0 && sluggedEvent?.colocated,
    });

  const startDateCounter = useMemo(() => {
    return counterDate(sluggedEvent?.start_date);
  }, [sluggedEvent?.start_date]);

  const TopHeaderComponent = useMemo(() => {
    if (isTablet) return null;

    return (
      <EventMenuRoot $backgroundColor={sluggedEvent?.design?.themeColor ?? ''}>
        <EventTopMenuBody>
          <TopHeaderMenuRoot>
            <RowAlignedItems $gap="2.4rem">
              <AddressText>
                {`${eventTimeFormat(
                  sluggedEvent?.start_date ?? '',
                  sluggedEvent?.end_date ?? '',
                  language
                )}

             ${sluggedEvent?.location?.name ? ` | ${sluggedEvent?.location?.name}` : ''}`}
              </AddressText>
            </RowAlignedItems>

            <RowAlignedItems>
              {startDateCounter && (
                <RowAlignedItems $gap="0.5rem">
                  <Icon
                    indentLess
                    name="event-pages_calendar"
                    color={theme.color.white}
                  />
                  <DateText color={theme.color.white}>
                    {`${t('still')} ${startDateCounter}`}{' '}
                  </DateText>
                </RowAlignedItems>
              )}
            </RowAlignedItems>
          </TopHeaderMenuRoot>
        </EventTopMenuBody>
      </EventMenuRoot>
    );
  }, [
    coLocatedEvents,
    sluggedEvent?.colocated,
    sluggedEvent?.design?.secondaryThemeColor,
    sluggedEvent?.end_date,
    sluggedEvent?.location?.name,
    sluggedEvent?.slug,
    sluggedEvent?.start_date,
    isCoLocatedEventsLoading,
    isTablet,
    language,
    startDateCounter,
    t,
    theme.color.mobilityGrey,
    theme.color.strokeGrey,
    theme.color.white,
  ]);

  if (mode !== MODE.EVENT) return null;

  return (
    <NavBarRoot>
      <EventMenuDesktop TopHeaderComponent={TopHeaderComponent} />

      <EventMenuMobile
        TopHeaderComponentTop={<HeaderTopComponent />}
        TopHeaderComponentBottom={<HeaderBottomComponent />}
      />
    </NavBarRoot>
  );
};
