import { useCallback, useMemo } from 'react';
import {
  ButtonWrapper,
  EventMenuBody,
  EventMenuRoot,
  LeftSideContent,
  LogoContainer,
  LogoImage,
  RightSideContent,
} from './styles';

import weLogo from '@assets/images/weCONECT_col_RGB.svg';
import Button from '@components/Button';
import MainMenuItemDesktop from '@composites/MainMenuItemDesktop';
import { MenuItem as MenuItemType } from '@hubs101/booking-api-skd-client/lib/types';
import { getEventDetails } from '@store/slices/eventSlice';
import { getImageSrc } from '@utils/fn';
import { useDesktopWidth } from '@utils/hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { bookingLink } from '@constants/mappings';
import { ROOT_URL, usePublicCoLocatedEventsByEventId } from '@utils/apiHooks';
import Skeleton from 'react-loading-skeleton';
import WeMenuItem from '@composites/WeMenuItem';

export const mappingLinks = {
  home: '',
  agenda: 'agenda-url',
  speaker: 'speakers-url',
  partner: 'partners-url',
  'event-info': 'event-info-url',
  'who-why': 'who-&-why-url',
  'content-hub': 'content-hub-url',
};

const EventMenu = ({
  TopHeaderComponent,
}: {
  TopHeaderComponent?: JSX.Element | null;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const sluggedEvent = useSelector(getEventDetails)?.[0];
  const isDesktop = useDesktopWidth();

  const { data: coLocatedEvents, isLoading: isCoLocatedEventsLoading } =
    usePublicCoLocatedEventsByEventId({
      eventId: sluggedEvent?.id,
      enabled: sluggedEvent?.id?.length > 0 && sluggedEvent?.colocated,
    });

  const sortedMenuItems = useMemo(
    () =>
      sluggedEvent?.website_menu
        ?.find(e => e.type === 'mainmenu')
        ?.items?.toSorted(
          (a: MenuItemType, b: MenuItemType) => a.order - b.order
        ),
    [sluggedEvent?.website_menu]
  );

  const link = useCallback(
    (item: MenuItemType) => {
      switch (item.type) {
        case 'internal':
          return `/${item.url}`;
        case 'external':
          return item.url;
        default:
          return t(mappingLinks[item.type as keyof typeof mappingLinks]);
      }
    },
    [t]
  );

  const mainMenuItems = useMemo(
    () =>
      sortedMenuItems
        ?.filter(e => !e.level)
        ?.slice(0, 6)
        ?.map(item => {
          return {
            id: item.label,
            title: item.label,
            subTitle: item.label,
            link: link(item),
            order: item.order,
          };
        }),
    [link, sortedMenuItems]
  );

  const secondaryMenuItems = useMemo(
    () =>
      sortedMenuItems
        ?.filter(e => e.level)
        ?.map(item => {
          return {
            id: item.label,
            title: item.label,
            subTitle: item.label,
            link: link(item),
            order: item.order,
            index: mainMenuItems?.findLastIndex(
              element => element.order < item.order
            ),
          };
        }) ?? [],
    [link, mainMenuItems, sortedMenuItems]
  );

  if (isDesktop) return null;

  return (
    <>
      {TopHeaderComponent}
      <EventMenuRoot>
        <EventMenuBody>
          <LeftSideContent>
            <LogoContainer $size={4}>
              <LogoImage
                src={
                  sluggedEvent?.design?.eventLogoPosSvg?.length > 0
                    ? getImageSrc(sluggedEvent?.design?.eventLogoPosSvg)
                    : weLogo
                }
                alt={
                  sluggedEvent?.name?.length
                    ? sluggedEvent?.name
                    : 'WeConect Logo'
                }
                $height={`${4}rem`}
              />
            </LogoContainer>

            {mainMenuItems?.map((item, index) => (
              <MainMenuItemDesktop
                key={item.id}
                title={item.title}
                link={item.link}
                subMenuItems={secondaryMenuItems?.filter(
                  item => item.index === index
                )}
              />
            ))}
          </LeftSideContent>

          <RightSideContent>
            <ButtonWrapper>
              <Button
                title={t('book-now')}
                backgroundColor={sluggedEvent?.design?.themeColor}
                hoverBackgroundColor={theme.color.mobilityGrey}
                textColor={theme.color.white}
                hoverTextColor={theme.color.white}
                borderRadius={theme.borderRadius.radius10}
                fontSize={theme.fontSize.size20}
                lineHeight={theme.lineHeight.height150}
                padding={'0.5rem 2.9rem'}
                width="max-content"
                onClick={() => {
                  window.open(
                    `${bookingLink[ROOT_URL]}purchase/${sluggedEvent?.slug}`,
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
              />
            </ButtonWrapper>

            {sluggedEvent?.colocated && (
              <>
                {isCoLocatedEventsLoading ? (
                  <Skeleton
                    count={1}
                    height="100%"
                    width="15rem"
                    enableAnimation
                    baseColor={sluggedEvent?.design?.secondaryThemeColor}
                  />
                ) : (
                  <WeMenuItem
                    title={t('co-located-events')}
                    coLocatedEvents={coLocatedEvents}
                    backgroundColor={sluggedEvent?.design?.secondaryThemeColor}
                    hoverBackgroundColor={theme.color.strokeGrey}
                  />
                )}
              </>
            )}
          </RightSideContent>
        </EventMenuBody>
      </EventMenuRoot>
    </>
  );
};

export default EventMenu;
