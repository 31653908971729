import { getEventDetails } from '@store/slices/eventSlice';
import {
  Image,
  InfoFooterWrapper,
  GridContainer,
  TextWrapper,
  AttendeeInfoRoot,
  Underline,
  RoleText,
  TitleText,
  Subheadline,
  DescriptionText,
  SocialIcons,
  SubtitleWrapper,
} from './styles';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import {
  formatWebsiteLink,
  getFullFacebookHttpLink,
  getFullInstagramHttpLink,
  getFullLinkedInHttpLink,
  getFullTwitterHttpLink,
  getFullXingHttpLink,
} from '@utils/fn';
import Icon from '@components/Icon';
import { bookingLink } from '@constants/mappings';
import { ROOT_URL } from '@utils/apiHooks';
import defaultProfileImage from '@assets/images/Default_Profile.png';

// MOCKED data for now
const speaker = {
  name: 'Andreas Freutmiedl',
  image: defaultProfileImage,
  jobTitle: 'Head of Finance and Risk Management',
  aboutMe:
    "Top level networking meets concentrated expertise: Our event offers numerous networking opportunities to get into conversation with speakers, participants, moderators and solution providers. To ensure that you can discuss your specific interests in a targeted manner and network appropriately, selected matching processes are already in place in advance. Join our direct exchange with numerous personally invited and carefully selected executives from well-known companies and jointly discuss best practices, trends, current projects and issues from your industry or pave the way for long-term planning - that's what the Auto.AI USA stands for. Our event offers numerous networking opportunities to get into conversation with speakers, participants, moderators and solution providers. To ensure that you can discuss your specific interests in a targeted manner and network appropriately, selected matching processes are already in place in advance.",
  contact: {
    facebook: 'https://facebook.com/SamaAI',
    twitter: 'https://twitter.com/SamaAI',
    instagram: '',
    linkedin: 'https://www.linkedin.com/in/renata-walker',
    xing: 'https://www.linkedin.com/company/sama-ai/',
    youtube: '',
  },
};

const AttendeeInfo = () => {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const sluggedEvent = useSelector(getEventDetails)?.[0];

  const socialMediaIcons = [
    speaker?.contact?.linkedin && {
      icon: 'social_linkedin',
      onClick: () =>
        window.open(
          getFullLinkedInHttpLink(speaker.contact.linkedin),
          '_blank'
        ),
    },
    speaker?.contact?.xing && {
      icon: 'social_xing',
      onClick: () => {
        window.open(getFullXingHttpLink(speaker.contact.xing), '_blank');
      },
    },
    speaker?.contact?.twitter && {
      icon: 'social_twitter',
      onClick: () =>
        window.open(getFullTwitterHttpLink(speaker.contact.twitter), '_blank'),
    },
    speaker?.contact?.facebook && {
      icon: 'social_facebook',
      onClick: () =>
        window.open(
          getFullFacebookHttpLink(speaker.contact.facebook),
          '_blank'
        ),
    },
    speaker?.contact?.youtube && {
      icon: 'social_youtube',
      onClick: () =>
        window.open(formatWebsiteLink(speaker.contact.youtube), '_blank'),
    },
    speaker?.contact?.instagram && {
      icon: 'social_instagram',
      onClick: () =>
        window.open(
          getFullInstagramHttpLink(speaker.contact.instagram),
          '_blank'
        ),
    },
  ];

  return (
    <AttendeeInfoRoot>
      <TitleText color={sluggedEvent?.design?.headerFontColor}>
        {speaker.name}
      </TitleText>

      <SubtitleWrapper>
        <RoleText color={sluggedEvent?.design?.headerFontColor}>
          {speaker.jobTitle}
        </RoleText>
        <Underline color={sluggedEvent?.design?.themeColor} />
      </SubtitleWrapper>

      <GridContainer>
        <Image src={speaker?.image || defaultProfileImage} />

        <TextWrapper>
          <div>
            <Subheadline> {t('about-me')} </Subheadline>
            <DescriptionText>{speaker.aboutMe}</DescriptionText>
          </div>

          <InfoFooterWrapper>
            <SocialIcons>
              {socialMediaIcons
                .filter(item => !!item)
                .map(item => (
                  <Icon
                    key={item.icon}
                    testId={item.icon}
                    name={item.icon}
                    color={theme.color.black}
                    $hoverColor={theme.color.primary}
                    $clickable
                    onClick={item.onClick}
                    indentLess
                    size={40}
                    $hoverEffect
                  />
                ))}
            </SocialIcons>

            <Button
              title={t('book-now')}
              backgroundColor={sluggedEvent?.design?.themeColor}
              hoverBackgroundColor={theme.color.mobilityGrey}
              textColor={theme.color.white}
              borderRadius={theme.borderRadius.radius10}
              padding={'1.1rem 4.9rem'}
              width="max-content"
              onClick={() => {
                window.open(
                  `${bookingLink[ROOT_URL]}purchase/${sluggedEvent?.slug}?la=${i18n.language}`,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            />
          </InfoFooterWrapper>
        </TextWrapper>
      </GridContainer>
    </AttendeeInfoRoot>
  );
};

AttendeeInfo.displayName = 'AttendeeInfo';

export default AttendeeInfo;
