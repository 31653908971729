import styled from 'styled-components';

export const Column = styled.div.attrs<{
  margin?: string;
  $gap?: string;
  $alignItems?: string;
  $justifyContent?: string;
  $width?: string;
  $height?: string;
  $padding?: string;
}>(props => ({
  margin: props.margin,
  gap: props.$gap,
  height: props.$height,
  width: props.$width,
  padding: props.$padding,
}))`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => $gap};
  margin: ${({ margin }) => margin};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  align-items: ${({ $alignItems }) => $alignItems};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  padding: ${({ $padding }) => $padding};
`;

export const Row = styled.div.attrs<{
  margin?: string;
  $gap?: string;
  $alignItems?: string;
  $justifyContent?: string;
  $width?: string;
  $height?: string;
}>(props => ({
  margin: props.margin,
  gap: props.$gap,
  alignItems: props.$alignItems,
  justifyContent: props.$justifyContent,
  width: props.$width,
  height: props.$height,
}))`
  display: flex;
  flex-direction: row;
  gap: ${({ $gap }) => $gap};
  margin: ${({ margin }) => margin};
  align-items: ${({ $alignItems }) => $alignItems};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  width: ${({ $width }) => $width};
`;

export const RowAlignedItems = styled(Row)<{ $alignItem?: string }>`
  align-items: ${({ $alignItems }) => $alignItems ?? 'center'};
`;

export const RowSpace = styled.div<{ $height?: string }>`
  height: ${({ $height }) => $height ?? '1rem'};
`;
export const ColumnSpace = styled.div<{ $width?: string }>`
  width: ${({ $width }) => $width ?? '1rem'};
`;

export const CenterItem = styled.div`
  display: grid;
  place-items: center;
`;

export const DarkSection = styled.div`
  background-color: ${({ theme }) => theme.color.mobilityGrey};
`;

export const WhiteSection = styled.div`
  background-color: ${({ theme }) => theme.color.white};
`;

export const LightGreySection = styled.div`
  background-color: ${({ theme }) => theme.color.lightGrey2};
`;
