import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DividerWrapper,
  MainMenuDropdown,
  MainMenuItemRoot,
  MainMenuItemWrapper,
  RotateIcon,
  SubMenuItems,
} from './styles';

import Divider from '@components/Divider';
import Icon from '@components/Icon';
import SubMenuItem from '@composites/SubMenuItem';
import { adminEventsRouteFormat } from '@constants/paths';
import { useSpring } from '@react-spring/web';
import { getEventDetails } from '@store/slices/eventSlice';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

interface MenuItemProps {
  title: string;
  link: string;
  subMenuItems?: Array<{
    id: string;
    title: string;
    subTitle: string;
    link: string;
    order: number;
    index: number | undefined;
  }>;
}

const MainMenuItemDesktop = ({ title, link, subMenuItems }: MenuItemProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isHovered, setIsHovered] = useState<boolean | null>(null);
  const sluggedEvent = useSelector(getEventDetails)?.[0];

  const props = useSpring({
    from: { transform: 'rotateZ(0deg)' },
    to: { transform: 'rotateZ(-180deg)' },
    config: { duration: 300 },
    reverse: !isHovered,
  });

  const isSelected = useMemo(() => {
    const url = pathname.split('/')?.[3]?.length
      ? `/${pathname.split('/')?.[3]}`
      : pathname.split('/')?.[3];
    return url === link;
  }, [link, pathname]);

  return (
    <MainMenuItemRoot
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false);
      }}
    >
      <MainMenuItemWrapper
        $isSelected={isSelected}
        onClick={() => {
          navigate(adminEventsRouteFormat(sluggedEvent?.slug, link));
        }}
        $color={sluggedEvent?.design?.themeColor}
        $isMultiMenu={Number(subMenuItems?.length) > 0}
        $isHovered={isHovered}
      >
        {subMenuItems?.length === 0 ? (
          <>
            {title}
            <DividerWrapper $isHovered={isHovered || isSelected}>
              <Divider
                width="10rem"
                height="0.5rem"
                backgroundColor={sluggedEvent?.design.themeColor}
              />
            </DividerWrapper>
          </>
        ) : (
          <>
            <MainMenuDropdown>
              {title}

              <RotateIcon style={props}>
                <Icon
                  name="caret_arrow"
                  color={theme.color.mobilityGrey}
                  $hoverColor={sluggedEvent?.design.themeColor}
                  indentLess
                />
              </RotateIcon>
            </MainMenuDropdown>
          </>
        )}
      </MainMenuItemWrapper>
      {Number(subMenuItems?.length) > 0 && (
        <SubMenuItems $isHovered={isHovered}>
          {subMenuItems?.map(menuItem => (
            <SubMenuItem
              key={menuItem.order}
              title={menuItem.title}
              onClick={() => {
                navigate(
                  adminEventsRouteFormat(sluggedEvent?.slug, menuItem.link)
                );
              }}
            />
          ))}
        </SubMenuItems>
      )}
    </MainMenuItemRoot>
  );
};

export default MainMenuItemDesktop;
