import AttendeeInfo from '@composites/AttendeeInfo';
import CompanyInfo from '@composites/CompanyInfo';
import Sessions from '@composites/Sessions';
import HeroSection from '@composites/HeroSection';
import { useTranslation } from 'react-i18next';

const SpeakerPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeroSection
        title={t('best-in-class-speakers-will-share-their-expertise')}
        description={t('experience-top-experts-text')}
        buttonText={t('apply-as-speaker')}
        buttonURL={''}
      />
      <AttendeeInfo />
      <Sessions />
      <CompanyInfo />
    </>
  );
};

export default SpeakerPage;
