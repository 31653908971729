import { Row, RowAlignedItems } from '@components/LinearLayout';

import { PSmall } from '@components/Typography';
import styled from 'styled-components';

export const TopHeaderMenuRoot = styled.div<{ $color?: string }>`
  background-color: ${({ $color, theme }) => $color || theme.color.transparent};
  display: flex;
  gap: 2rem;
  justify-content: start;
  width: 100%;
`;

export const TopHeaderBody = styled(Row)<{ $isLeft: boolean }>`
  padding: ${({ $isLeft }) =>
    $isLeft ? '1.7rem 1.7rem 1.7rem 10rem' : '1.7rem 10rem 1.7rem 1.7rem'};
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin: auto;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1280}) {
    padding: ${({ $isLeft }) =>
      $isLeft ? '1.7rem 1.7rem 1.7rem 9rem' : '1.7rem 9rem 1.7rem 1.7rem'};
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    padding: 1rem 2rem;
  }
`;

export const LeftSideContent = styled(RowAlignedItems)`
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const RightSideContent = styled(Row)`
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const AddressText = styled(PSmall)<{ padding?: string }>`
  color: ${props => props.theme.color.white};
  font-weight: ${({ theme }) => theme.fontWeight.weight400};
  font-size: ${({ theme }) => theme.fontSize.size18};
  padding: ${({ padding }) => padding || '1rem 0 1rem 1rem'};
  white-space: nowrap;
`;

export const DateText = styled(PSmall)<{ color?: string }>`
  color: ${props => props?.color || props.theme.color.mobilityGrey};
  font-weight: ${({ theme }) => theme.fontWeight.weight500};
  font-size: ${({ theme }) => theme.fontSize.size16};
  white-space: nowrap;
`;
