import { getEventDetails } from '@store/slices/eventSlice';
import { SessionsRoot, Underline, TitleText } from './styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SessionInfoItem from './SessionInfoItem';

// MOCKED data for now
export interface SessionItem {
  dateString: string;
  title: string;
  description: string;
  startingTime: string;
  endingTime: string;
  location: string;
  city: string;
}
const sessions: SessionItem[] = [
  {
    dateString: '09/03/2025',
    title:
      'User-Journey und Produktkonfiguration - Effizientes Variantenmanagement in der digitalen Umgebung',
    description:
      'In diesem Vortrag erfahren Sie, wie die Analyse der User-Journey und eine effiziente Produktkonfiguration Ihre digitale Umgebung optimieren können. Sie lernen, wie Sie die Bedürfnisse und Erwartungen Ihrer Kunden besser verstehen, die Komplexität der Produktvarianten bewältigen und die Benutzererfahrung verbessern. In diesem Vortrag erfahren Sie, wie die Analyse der User-Journey und eine effiziente Produktkonfiguration Ihre digitale Umgebung optimieren können. Sie lernen, wie Sie die Bedürfnisse und Erwartungen Ihrer Kunden besser verstehen, die Komplexität der Produktvarianten bewältigen und die Benutzererfahrung verbessern. In diesem Vortrag erfahren Sie, wie die Analyse der User-Journey und eine effiziente Produktkonfiguration Ihre digitale Umgebung optimieren können. Sie lernen, wie Sie die Bedürfnisse und Erwartungen Ihrer Kunden besser verstehen, die Komplexität der Produktvarianten bewältigen und die Benutzererfahrung verbessern. ',
    startingTime: '15:00',
    endingTime: '15:45',
    location: 'Hotel Adlon',
    city: 'Berlin',
  },
  {
    dateString: '10/03/2025',
    title:
      'User-Journey und Produktkonfiguration - Effizientes Variantenmanagement in der digitalen Umgebung',
    description:
      'In diesem Vortrag erfahren Sie, wie die Analyse der User-Journey und eine effiziente Produktkonfiguration Ihre digitale Umgebung optimieren können. Sie lernen, wie Sie die Bedürfnisse und Erwartungen Ihrer Kunden besser verstehen, die Komplexität der Produktvarianten bewältigen und die Benutzererfahrung verbessern. ',
    startingTime: '15:00',
    endingTime: '15:45',
    location: 'Hotel Adlon',
    city: 'Berlin',
  },
];

const Sessions = () => {
  const { t } = useTranslation();
  const sluggedEvent = useSelector(getEventDetails)?.[0];

  return (
    <SessionsRoot>
      <TitleText color={sluggedEvent?.design?.headerFontColor}>
        {t('sessions')}
      </TitleText>
      <Underline color={sluggedEvent?.design?.themeColor} />

      {sessions.map((sessionItem: SessionItem) => {
        return (
          <SessionInfoItem
            key={`${sessionItem.dateString}-${sessionItem.city}`}
            item={sessionItem}
          />
        );
      })}
    </SessionsRoot>
  );
};

Sessions.displayName = 'Sessions';

export default Sessions;
