import { getEventDetails } from '@store/slices/eventSlice';
import {
  Image,
  InfoFooterWrapper,
  GridContainer,
  TextWrapper,
  CompanyInfoRoot,
  Underline,
  TitleText,
  Subheadline,
  DescriptionText,
  SocialIcons,
  CompanyURL,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import {
  formatWebsiteLink,
  getFullFacebookHttpLink,
  getFullInstagramHttpLink,
  getFullLinkedInHttpLink,
  getFullTwitterHttpLink,
  getFullXingHttpLink,
} from '@utils/fn';
import Icon from '@components/Icon';
import fordImage from '@assets/images/ford.png';
import defaultProfileImage from '@assets/images/Default_Profile.png';

// MOCKED data for now
const company = {
  name: 'Ford Motor Company',
  image: fordImage,
  link: 'www.ford.com',
  jobTitle: 'Head of Finance and Risk Management',
  aboutMe:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis ultrices purus. Duis vestibulum turpis quis odio laoreet ultrices. Ut viverra neque a est mattis aliquet. Ut non sem tincidunt, tincidunt mi sed, commodo libero. Aliquam gravida, orci vel placerat vehicula, mauris lacus viverra ligula, et venenatis quam odio at odio. Aenean quis sagittis metus. Integer vel lacus molestie, molestie augue vel, lacinia neque. Suspendisse ut augue venenatis, vehicula ipsum eget, ullamcorper neque. Nam semper feugiat nunc. Proin congue pellentesque dui vulputate imperdiet. Nulla aliquam velit sit amet mollis sollicitudin. Duis quis auctor purus. Duis id congue leo, et accumsan erat. Nunc id semper enim. Fusce lectus dolor, egestas dapibus risus nec, congue mattis velit. Etiam volutpat, nisi id lacinia porttitor, velit eros ullamcorper ante, vitae vestibulum urna lectus a diam.',
  contact: {
    facebook: 'https://facebook.com/SamaAI',
    twitter: 'https://twitter.com/SamaAI',
    instagram: '',
    linkedin: 'https://www.linkedin.com/in/renata-walker',
    xing: 'https://www.linkedin.com/company/sama-ai/',
    youtube: '',
  },
};

const CompanyInfo = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const sluggedEvent = useSelector(getEventDetails)?.[0];

  const socialMediaIcons = [
    company?.contact?.linkedin && {
      icon: 'social_linkedin',
      onClick: () =>
        window.open(
          getFullLinkedInHttpLink(company.contact.linkedin),
          '_blank'
        ),
    },
    company?.contact?.xing && {
      icon: 'social_xing',
      onClick: () => {
        window.open(getFullXingHttpLink(company.contact.xing), '_blank');
      },
    },
    company?.contact?.twitter && {
      icon: 'social_twitter',
      onClick: () =>
        window.open(getFullTwitterHttpLink(company.contact.twitter), '_blank'),
    },
    company?.contact?.facebook && {
      icon: 'social_facebook',
      onClick: () =>
        window.open(
          getFullFacebookHttpLink(company.contact.facebook),
          '_blank'
        ),
    },
    company?.contact?.youtube && {
      icon: 'social_youtube',
      onClick: () =>
        window.open(formatWebsiteLink(company.contact.youtube), '_blank'),
    },
    company?.contact?.instagram && {
      icon: 'social_instagram',
      onClick: () =>
        window.open(
          getFullInstagramHttpLink(company.contact.instagram),
          '_blank'
        ),
    },
  ];

  return (
    <CompanyInfoRoot>
      <GridContainer>
        <Image src={company?.image || defaultProfileImage} />

        <TextWrapper>
          <div>
            <TitleText color={sluggedEvent?.design?.headerFontColor}>
              {company.name}
            </TitleText>
            <Underline color={sluggedEvent?.design?.themeColor} />

            <Subheadline> {t('Company Description')} </Subheadline>
            <DescriptionText>{company.aboutMe}</DescriptionText>
          </div>

          <InfoFooterWrapper>
            <SocialIcons>
              {socialMediaIcons
                .filter(item => !!item)
                .map(item => (
                  <Icon
                    key={item.icon}
                    testId={item.icon}
                    name={item.icon}
                    color={theme.color.black}
                    $hoverColor={theme.color.primary}
                    $clickable
                    onClick={item.onClick}
                    indentLess
                    size={40}
                    $hoverEffect
                  />
                ))}
            </SocialIcons>

            <CompanyURL
              href={
                company.link.startsWith('http')
                  ? company.link
                  : `https://${company.link}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {company.link}
            </CompanyURL>
          </InfoFooterWrapper>
        </TextWrapper>
      </GridContainer>
    </CompanyInfoRoot>
  );
};

CompanyInfo.displayName = 'CompanyInfo';

export default CompanyInfo;
