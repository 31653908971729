import styled from 'styled-components';

export const SessionInfoRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr 8fr;
  gap: 2rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    grid-template-columns: 1fr;
  }
`;

export const ColoredWrapper = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.color.black};
  color: ${({ theme }) => theme.color.white};
  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    grid-template-columns: 1fr 3fr;
  }
`;

export const DateColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const InfoColumn = styled.div<{ backgroundColor?: string }>`
  display: grid;
  gap: 3rem;
`;

export const Underline = styled.div<{
  color?: string;
}>`
  width: 160px;
  height: 0.4rem;
  background-color: ${({ color, theme }) => color || theme.color.white};
  margin-top: 1.5rem;
`;

export const TitleText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.size30};
  line-height: ${({ theme }) => theme.lineHeight.height115};
  margin-top: 1rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    font-size: ${({ theme }) => theme.fontSize.size18};
  }
`;

export const DescriptionText = styled.div<{ isExpanded?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.size18};
  line-height: ${({ theme }) => theme.lineHeight.height150};
  max-height: ${({ isExpanded }) =>
    isExpanded ? '800px' : '78px'}; /* Adjust heights as needed */
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    font-size: ${({ theme }) => theme.fontSize.size16};
  }
`;

export const LocationWrapper = styled.span`
  font-weight: 600;
  padding-left: 0.5rem;
`;

export const DesktopFooterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
`;

export const FooterItemWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.size18};

  i {
    width: 2.8rem;
    padding-right: 1rem;

    @media only screen and (max-width: ${({ theme }) =>
        theme.breakpoint.width768}) {
      padding-left: 2rem;
      padding-right: 3rem;
    }
  }
`;

export const MoreDetailsWrapper = styled(FooterItemWrapper)<{
  justifyContent?: string;
}>`
  display: flex;
  align-items: center;
  justify-items: end;
  justify-content: ${({ justifyContent }) => justifyContent || 'end'};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.size20};

  i {
    @media only screen and (max-width: ${({ theme }) =>
        theme.breakpoint.width768}) {
      padding-left: 1rem;
    }
  }
`;

export const DateDay = styled.div`
  font-size: ${({ theme }) => theme.fontSize.size55};
  line-height: ${({ theme }) => theme.lineHeight.height115};

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    font-size: ${({ theme }) => theme.fontSize.size40};
  }
`;

export const DateMonth = styled.div`
  font-size: ${({ theme }) => theme.fontSize.size32};
  line-height: ${({ theme }) => theme.lineHeight.height115};

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    font-size: ${({ theme }) => theme.fontSize.size18};
  }
`;

export const DateYear = styled.div`
  font-size: ${({ theme }) => theme.fontSize.size16};

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    font-size: ${({ theme }) => theme.fontSize.size14};
  }
`;
