import { getEventDetails } from '@store/slices/eventSlice';
import {
  ColoredWrapper,
  DateColumn,
  DateDay,
  DateMonth,
  DateYear,
  DescriptionText,
  DesktopFooterWrapper,
  FooterItemWrapper,
  InfoColumn,
  LocationWrapper,
  MoreDetailsWrapper,
  SessionInfoRoot,
  TitleText,
  Underline,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SessionItem } from '..';
import { useMobileWidth, useTabletWidth } from '@utils/hooks';
import Icon from '@components/Icon';
import { useTheme } from 'styled-components';
import { useState } from 'react';

const SessionInfoItem = ({ item }: { item: SessionItem }) => {
  const {
    dateString,
    title,
    description,
    startingTime,
    endingTime,
    location,
    city,
  } = item;
  const { t } = useTranslation();
  const sluggedEvent = useSelector(getEventDetails)?.[0];
  const theme = useTheme();
  const isMobile = useMobileWidth();
  const isTablet = useTabletWidth();

  const date = new Date(dateString.split('/').reverse().join('-'));
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();
  const year = date.getFullYear();

  const [isAllVisible, setIsAllVisible] = useState<boolean>(false);

  const toggleMoreLess = () => {
    setIsAllVisible(!isAllVisible);
  };

  return (
    <SessionInfoRoot>
      <ColoredWrapper backgroundColor={sluggedEvent?.design?.themeColor}>
        <DateColumn>
          <DateDay>{day}</DateDay>
          <DateMonth>{month}</DateMonth>
          <DateYear>{year}</DateYear>
        </DateColumn>

        {(isMobile || isTablet) && (
          <div>
            <FooterItemWrapper style={{ justifyContent: 'end' }}>
              {startingTime} - {endingTime}{' '}
              <Icon color={theme.color.white} name="clock" size={24} />
            </FooterItemWrapper>

            <FooterItemWrapper
              style={{ justifyContent: 'end', fontSize: theme.fontSize.size14 }}
            >
              {t('live-at')}
              <LocationWrapper>
                {location} {city}{' '}
              </LocationWrapper>
              <Icon color={theme.color.white} name="location" size={24} />
            </FooterItemWrapper>
          </div>
        )}
      </ColoredWrapper>

      <InfoColumn>
        <div>
          <TitleText>{title}</TitleText>
          <Underline color={sluggedEvent?.design?.themeColor} />
        </div>

        <DescriptionText isExpanded={isAllVisible}>
          {description}
        </DescriptionText>

        {(isMobile || isTablet) && (
          <MoreDetailsWrapper onClick={toggleMoreLess} justifyContent="start">
            <Icon
              color={theme.color.shadeGrey2}
              name={`event-pages_${isAllVisible ? 'up' : 'down'}-arrow`}
              size={24}
            />
            {t(isAllVisible ? 'less-details' : 'more-details')}
          </MoreDetailsWrapper>
        )}

        {!isMobile && !isTablet && (
          <DesktopFooterWrapper>
            <FooterItemWrapper>
              <Icon
                color={sluggedEvent?.design?.themeColor}
                name="clock"
                size={24}
              />
              {startingTime} - {endingTime}{' '}
            </FooterItemWrapper>

            <FooterItemWrapper>
              <Icon
                color={sluggedEvent?.design?.themeColor}
                name="location"
                size={24}
              />
              {t('live-at')}
              <LocationWrapper>
                {location} {city}{' '}
              </LocationWrapper>
            </FooterItemWrapper>

            <MoreDetailsWrapper onClick={toggleMoreLess}>
              <Icon
                color={theme.color.shadeGrey2}
                name={`event-pages_${isAllVisible ? 'up' : 'down'}-arrow`}
                size={24}
              />
              {t(isAllVisible ? 'less-details' : 'more-details')}
            </MoreDetailsWrapper>
          </DesktopFooterWrapper>
        )}
      </InfoColumn>
    </SessionInfoRoot>
  );
};

SessionInfoItem.displayName = 'SessionInfoItem';

export default SessionInfoItem;
