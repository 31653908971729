import {
  InboundData,
  VisitorDataInput,
} from '@hubs101/booking-api-skd-client/lib/types';
import {
  getPublicAgenda,
  getPublicAttendees,
  getPublicCoLocatedEventsByEventId,
  getPublicEventDetailsById,
  getPublicEventDetailsBySlug,
  getPublicSpeakerGroupsByCategory,
  getPublicEventExhibitions,
  getPublicEventPageDetailsBySlug,
  getPublicGroupDetailsById,
  getPublicPortfolioEvents,
  getPublicPortfoliosByAccount,
  getVisitor,
  postInbound,
  postVisitor,
  getPublicExhibitionGroupsByCategory,
} from '@hubs101/booking-api-skd-client';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';

import { safeJSONParse } from './fn';
import { BaseAPI } from '@hubs101/js-api-skd-client';
import { GroupServerResponse } from '@hubs101/js-api-skd-client/lib/api/event/types';
import { PublicAdResponse } from '@hubs101/js-api-skd-client/lib/api/public/types';

export const ROOT_URL = process.env.ROOT_URL as string;
export const BASE_MEDIA_URL = process.env.BASE_MEDIA_URL as string;
export const ACCOUNT_ID = process.env.ACCOUNT_ID as string;
export const WE_WEBSITE_RE_CAPTCHA_SITE_KEY = process.env
  .RECAPTCHA_KEY as string;

export const usePublicAttendees = ({
  eventId,
  enabled,
  params,
}: {
  eventId: string;
  enabled: boolean;
  params?: string;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, eventId, params],
    queryFn: async () => {
      return await getPublicAttendees({
        baseUrl: ROOT_URL ?? '',
        eventId,
        params,
      });
    },
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicPortfolioByAccount = ({
  accountId,
  page = 1,
  slug,
}: {
  accountId: string;
  page?: number;
  slug?: string;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, accountId, slug, page],
    queryFn: async () =>
      await getPublicPortfoliosByAccount({
        baseUrl: ROOT_URL ?? '',
        accountId,
        page,
        slug,
      }),
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicEventDetails = ({ eventId }: { eventId: string }) => {
  return useQuery({
    queryKey: [ROOT_URL, eventId],
    queryFn: async () =>
      await getPublicEventDetailsById({
        baseUrl: ROOT_URL ?? '',
        eventId,
      }),
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicEventDetailsBySlug = ({
  eventSlug,
  enabled,
}: {
  eventSlug: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, eventSlug],
    queryFn: async () =>
      await getPublicEventDetailsBySlug({
        baseUrl: ROOT_URL ?? '',
        eventSlug,
      }),
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicPortfolioEvents = ({
  accountId,
  portfolioSlug,
  params,
  enabled,
}: {
  accountId: string;
  portfolioSlug: string;
  params: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, accountId, portfolioSlug, params],
    queryFn: async () =>
      await getPublicPortfolioEvents({
        baseUrl: ROOT_URL ?? '',
        accountId,
        portfolioSlug,
        params: params ?? '',
      }),
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicEventPageDetailsBySlug = ({
  eventId,
  pageSlug,
  enabled,
}: {
  eventId: string;
  pageSlug: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, pageSlug, eventId],
    queryFn: async () =>
      await getPublicEventPageDetailsBySlug({
        baseUrl: ROOT_URL ?? '',
        eventId,
        pageSlug,
      }),
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicGroupDetailsById = ({
  groupId,
  enabled,
}: {
  groupId: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, groupId],
    queryFn: async () =>
      await getPublicGroupDetailsById({
        baseUrl: ROOT_URL ?? '',
        groupId,
      }),
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicCoLocatedEventsByEventId = ({
  eventId,
  enabled,
}: {
  eventId: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, eventId],
    queryFn: async () =>
      await getPublicCoLocatedEventsByEventId({
        baseUrl: ROOT_URL ?? '',
        eventId,
      }),
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicAgendas = ({
  page = 1,
  language = 'en',
  eventIds,
  enabled,
}: {
  page?: number;
  language?: string;
  eventIds: string[];
  enabled: boolean;
}) => {
  return useQueries({
    queries: eventIds?.map((event: string) => {
      return {
        queryKey: [ROOT_URL, page, language, event, 'agenda'],
        queryFn: async () => {
          return await getPublicAgenda({
            baseUrl: ROOT_URL ?? '',
            page,
            eventId: event,
            language,
          });
        },
        enabled,
        staleTime: 180000,
        retry: false,
      };
    }),
  });
};

export const usePublicExhibitions = ({
  page = 1,
  language = 'en',
  eventIds,
  enabled,
}: {
  page?: number;
  language?: string;
  eventIds: string[];
  enabled: boolean;
}) => {
  return useQueries({
    queries: eventIds?.map((eventId: string) => {
      return {
        queryKey: [ROOT_URL, page, language, eventId, 'exhibitions'],
        queryFn: async () => {
          return await getPublicEventExhibitions({
            baseUrl: ROOT_URL ?? '',
            page,
            eventId,
            language,
          });
        },
        enabled,
        staleTime: 180000,
        retry: false,
      };
    }),
  });
};

export const useCreateVisitor = ({
  updatedData,
  newData,
}: {
  updatedData?: VisitorDataInput;
  newData?: VisitorDataInput;
}) =>
  useMutation({
    mutationFn: async (action: 'create' | 'update') =>
      await postVisitor({
        visitorData: (action === 'create'
          ? newData
          : updatedData) as VisitorDataInput,
      }),
  });

export const useCreateInbound = (
  inboundData: InboundData,
  secret?: string,
  onError?: (error: Error) => void
) =>
  useMutation({
    mutationFn: async (responseDetails?: string) =>
      await postInbound({
        inboundData: {
          ...inboundData,
          visitor: safeJSONParse(responseDetails)?.visitor ?? '',
        },
        secret: safeJSONParse(responseDetails)?.secret ?? secret ?? '',
      }),
    onError,
  });

export const useGetVisitor = ({
  visitorId,
  secret,
  enabled,
}: {
  visitorId: string;
  secret: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, visitorId, secret],
    queryFn: async () =>
      await getVisitor({
        visitorId,
        secret,
      }),
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const useGetPublicSpeakerGroupsByCategory = ({
  eventId,
  category,
}: {
  eventId: string;
  category: string;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, eventId, category, 'groups'],
    queryFn: async () => {
      return await getPublicSpeakerGroupsByCategory({
        baseUrl: ROOT_URL,
        eventId,
        category,
      });
    },
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const useGetPublicExhibitionGroupsByCategory = ({
  eventId,
  category,
}: {
  eventId: string;
  category: string;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, eventId, category, 'groups'],
    queryFn: async () => {
      return await getPublicExhibitionGroupsByCategory({
        baseUrl: ROOT_URL,
        eventId,
        category,
      });
    },
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const useGetMergedGroupsData = ({
  eventIds,
  categories, // the order of the items (categories) matters
}: {
  eventIds: string[];
  categories: string[];
}) => {
  return useQuery({
    queryKey: [ROOT_URL, eventIds, 'groups'],
    queryFn: async () => {
      const totalGroupData = await Promise.all(
        eventIds.map(async eventId => {
          let groupsData: GroupServerResponse[] = [];

          try {
            const data = await BaseAPI.fetchPublicEventGroups(
              ROOT_URL,
              eventId
            );
            groupsData = [...data];
          } catch (error) {
            throw new Error(`Failed to fetch event groups (id: ${eventId})`);
          }

          const relevantGroups: GroupServerResponse[] =
            groupsData?.filter(
              (group: GroupServerResponse) =>
                group.category && categories.includes(group.category)
            ) || [];

          return relevantGroups;
        })
      );
      const mergedGroupData = totalGroupData
        .flat()
        ?.sort(
          (a: GroupServerResponse, b: GroupServerResponse) =>
            categories.indexOf(a?.category || '') -
            categories.indexOf(b?.category || '')
        );

      const totalExhibitionGroupsData = await Promise.all(
        mergedGroupData.map(async item => {
          const data = await getPublicExhibitionGroupsByCategory({
            baseUrl: ROOT_URL,
            eventId: item.event,
            category: item.category || '',
          });

          return data.map(element => {
            return { ...element, category: item.category };
          });
        })
      );

      const exhibitionGroups = totalExhibitionGroupsData.flat();

      return exhibitionGroups;
    },
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const useGetPublicEventAdvertisement = ({
  eventId,
}: {
  eventId: string;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, eventId, 'groups'],
    queryFn: async () => {
      const data: any = await BaseAPI.fetchPublicEventAdvertisement(
        ROOT_URL,
        eventId
      );

      return [...data];
    },
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};
