import { Column, Row } from '@components/LinearLayout';
import styled, { css, keyframes } from 'styled-components';

export const menuAnimationDuration = 400;

const slideDown = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 100vh;
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    max-height: 100vh;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
`;

export const EventMenuRoot = styled(Column)`
  background-color: ${({ theme }) => theme.color.lightGrey2};
  display: flex;
  -webkit-box-shadow: inset 0 -0.5rem 0 ${({ theme }) => theme.color.strokeGrey};
  -moz-box-shadow: inset 0 -0.5rem 0 ${({ theme }) => theme.color.strokeGrey};
  box-shadow: inset 0 -0.5rem 0 ${({ theme }) => theme.color.strokeGrey};
  width: 100%;
`;

export const EventMenu = styled(Row)`
  padding: 1.7rem 10rem;
  align-items: center;
  justify-content: space-between;
`;

export const EventMenuBody = styled(Row)`
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-left: auto;
  margin-right: auto;
`;

export const TopContent = styled(Row)`
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
`;

export const BottomContent = styled(Column)<{ $isOpen: boolean }>`
  position: fixed;
  top: 80px;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;
  padding-bottom: 80px;

  background-color: ${({ theme }) => theme.color.greyLight};
  color: white;
  z-index: 105;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${slideDown} 0.4s ease-in forwards;
        `
      : css`
          animation: ${slideUp} 0.4s ease-out forwards;
          pointer-events: none;
          opacity: 0;
        `}
`;

export const ContentWrapper = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BottomDetailsContent = styled(Column)<{
  $open: boolean;
  $backgroundColor?: string;
}>`
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ?? theme.color.lightGrey2};
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  left: 100%;
  -webkit-transition: left ${menuAnimationDuration}ms;
  -moz-transition: left ${menuAnimationDuration}ms;
  -ms-transition: left ${menuAnimationDuration}ms;
  -o-transition: left ${menuAnimationDuration}ms;
  transition: left ${menuAnimationDuration}ms;

  ${({ $open }) => {
    if ($open)
      return css`
        left: 0;
      `;
    else
      return css`
        left: 100%;
      `;
  }}
`;

export const LogoContainer = styled.div<{ $size: number }>`
  display: flex;
  align-items: start;
  height: ${({ $size }) => $size}rem;
  cursor: pointer;
`;

export const LogoImage = styled.img<{ $height?: string }>`
  height: ${({ $height }) => $height};
  object-fit: contain;
`;

export const BackIcon = styled.div`
  margin: 1.5rem 0 0 1.5rem;
  width: 4rem;
  height: 4rem;
`;

export const SubMenuItems = styled(Column)``;

export const NextEventWrapper = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.color.grey1};
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 87px;
  min-height: 87px;
`;
