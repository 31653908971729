import {
  MainMenuDropdown,
  MainMenuItemRoot,
  RotateIcon,
} from '@composites/MainMenuItemDesktop/styles';
import {
  CoLocatedText,
  CompanyLogo,
  CompanyLogoContainer,
  SubMenuItems,
  WeMenuItemWrapper,
} from './styles';
import Button from '@components/Button';

import Icon from '@components/Icon';
import { adminEventsRouteFormat } from '@constants/paths';
import { PublicEventDetailsResponse } from '@hubs101/booking-api-skd-client/lib/types';
import { useSpring } from '@react-spring/web';
import { getEventDetails } from '@store/slices/eventSlice';
import { getImageSrc } from '@utils/fn';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { bookingLink } from '@constants/mappings';
import { ROOT_URL } from '@utils/apiHooks';
import { useTranslation } from 'react-i18next';
import { counterDate } from '@utils/time';
import { RowAlignedItems } from '@components/LinearLayout';
import { DateText } from '@composites/TopHeaderMenu/styles';
import { useMobileWidth, useTabletWidth } from '@utils/hooks';

interface WeMenuItemProps {
  title?: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  coLocatedEvents: PublicEventDetailsResponse[] | undefined;
}

const CompanyLogoWrapper = ({
  src,
  onClick,
}: {
  src: string;
  onClick: () => void;
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <CompanyLogoContainer
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false);
      }}
      onClick={onClick}
    >
      <CompanyLogo src={src} $isHovered={isHovered} />
    </CompanyLogoContainer>
  );
};

const WeMenuItem = ({
  title,
  backgroundColor,
  hoverBackgroundColor,
  coLocatedEvents,
}: WeMenuItemProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const sluggedEvent = useSelector(getEventDetails)?.[0];
  const isTablet = useTabletWidth();
  const isMobile = useMobileWidth();

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const props = useSpring({
    from: { transform: 'rotateZ(0deg)' },
    to: { transform: 'rotateZ(-180deg)' },
    config: { duration: 300 },
    reverse: !isHovered,
  });

  const startDateCounter = useMemo(() => {
    return counterDate(sluggedEvent?.start_date);
  }, [sluggedEvent?.start_date]);

  return (
    <MainMenuItemRoot
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false);
      }}
      onClick={() => {
        setIsHovered(!isHovered);
      }}
    >
      <WeMenuItemWrapper
        $backgroundColor={backgroundColor}
        $isHovered={isHovered}
        $hoverBackgroundColor={hoverBackgroundColor}
      >
        <MainMenuDropdown $gap="1rem">
          <CoLocatedText $color={theme.color.white}>{title}</CoLocatedText>

          <RotateIcon style={props}>
            <Icon name="caret_arrow" color={theme.color.white} indentLess />
          </RotateIcon>
        </MainMenuDropdown>
      </WeMenuItemWrapper>

      {Number(coLocatedEvents?.length) > 0 && (
        <SubMenuItems
          $isHovered={isHovered}
          $backgroundColor={theme.color.strokeGrey}
        >
          {coLocatedEvents
            ?.filter(e => e?.id !== sluggedEvent?.id)
            ?.map((sluggedEvent, index) => {
              return (
                <CompanyLogoWrapper
                  key={sluggedEvent?.id}
                  src={getImageSrc(sluggedEvent?.images.logoTransparent)}
                  onClick={() => {
                    navigate(adminEventsRouteFormat(sluggedEvent?.slug, ''));
                    setIsHovered(false);
                  }}
                />
              );
            })}

          {(isMobile || isTablet) && (
            <>
              {startDateCounter && (
                <RowAlignedItems $gap="0.5rem">
                  <Icon
                    indentLess
                    name="event-pages_calendar"
                    color={
                      sluggedEvent?.design?.themeColor ||
                      theme.color.mobilityGrey
                    }
                  />
                  <DateText>{`${t('still')} ${startDateCounter}`} </DateText>
                </RowAlignedItems>
              )}

              <Button
                title={t('book-now')}
                backgroundColor={sluggedEvent?.design?.themeColor}
                hoverBackgroundColor={theme.color.white}
                textColor={theme.color.white}
                hoverTextColor={sluggedEvent?.design?.themeColor}
                borderRadius={theme.borderRadius.radius10}
                fontSize={theme.fontSize.size18}
                lineHeight={theme.lineHeight.height150}
                padding={'1.5rem 4.5rem'}
                width="fit-content"
                onClick={() => {
                  window.open(
                    `${bookingLink[ROOT_URL]}purchase/${sluggedEvent?.slug}`,
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
              />
            </>
          )}
        </SubMenuItems>
      )}
    </MainMenuItemRoot>
  );
};

export default WeMenuItem;
