import styled from 'styled-components';

export const CompanyInfoRoot = styled.div`
  padding: 10rem 12rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    padding: 6rem 1.5rem;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 5rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1280}) {
    grid-template-columns: 2fr 1.5fr;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    grid-template-columns: 1fr;
  }
`;

export const Image = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Subheadline = styled.p`
  color: ${({ color, theme }) => color || theme.color.black};
  font-size: ${({ theme }) => theme.fontSize.size22};
  margin-top: 4rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: ${({ theme }) =>
    theme.breakpoint.width480}) {
    ${({ theme }) => theme.fontSize.size18};
`;

export const CompanyURL = styled.a`
  color: ${({ color, theme }) => color || theme.color.black};
  font-size: ${({ theme }) => theme.fontSize.size22};
  cursor: hover;
  text-decoration: none;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    font-size: ${({ theme }) => theme.fontSize.size18};
  }
`;

export const InfoFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    flex-direction: column;
  }
`;

export const TitleText = styled.h2<{ color?: string }>`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.fontSize.size40};
  line-height: ${({ theme }) => theme.lineHeight.height130};
`;

export const DescriptionText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.size18};
  line-height: ${({ theme }) => theme.lineHeight.height150};
  margin-bottom: 2rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    font-size: ${({ theme }) => theme.fontSize.size16};
  }
`;

export const Underline = styled.div<{
  color?: string;
}>`
  width: 160px;
  height: 0.4rem;
  background-color: ${({ color, theme }) => color || theme.color.white};
  margin-bottom: 1rem;
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 1.875rem;

  @media (max-width: ${({ theme }) => theme.breakpoint.width768}) {
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 2.5rem;
  }
`;
