import { scaleInType3, scaleOutType3 } from '@theme/animations';
import styled, { css, keyframes } from 'styled-components';

import { Column } from '@components/LinearLayout';
import { PSmall } from '@components/Typography';

const slideDown = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 100vh;
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    max-height: 100vh;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
`;

export const CoLocatedText = styled(PSmall)<{ $color?: string }>`
  color: ${({ $color }) => $color};
  font-weight: ${({ theme }) => theme.fontWeight.weight500};
  font-size: ${({ theme }) => theme.fontSize.size20};
  white-space: nowrap;
`;

export const SubMenuItems = styled(Column)<{
  $isHovered: boolean;
  $backgroundColor: string;
}>`
  gap: 2.5rem;
  list-style: none;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    position: absolute;
    top: 100%;
    left: 0;
    width: inherit;
    align-items: center;
    padding: 0;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.radius20};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.radius20};
    max-height: 0;
    z-index: 1;
    overflow: hidden;
    transition: max-height 300ms linear;
    ${({ $isHovered }) =>
      $isHovered
        ? css`
            max-height: 29rem;
            pointer-events: auto;
          `
        : css`
            max-height: 0;
            pointer-events: none;
          `}
    top: 80px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    position: fixed;
    top: 250px;
    left: 0;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 3rem;

    width: 100%;
    height: 100%;

    background: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.white};
    z-index: 105;

    ${({ $isHovered }) =>
      $isHovered
        ? css`
            animation: ${slideDown} 0.4s ease-in forwards;
          `
        : css`
            animation: ${slideUp} 0.4s ease-out forwards;
            pointer-events: none;
            opacity: 0;
          `}
  }
`;

export const WeMenuItemWrapper = styled.div<{
  $backgroundColor: string;
  $isHovered: boolean;
  $hoverBackgroundColor: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 2.9rem;
  border-radius: 1rem;
  transition: background-color 300ms ease-in-out;
  white-space: nowrap;
  height: 100%;

  ${({ $isHovered, $hoverBackgroundColor, theme }) =>
    $isHovered
      ? css`
          background-color: ${$hoverBackgroundColor};
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      : css`
          background-color: ${theme.color.mobilityGrey};
        `}

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    border-radius: 0;
    padding: 2.5rem 0rem;
  }
`;

export const CompanyLogo = styled.img<{ $isHovered: boolean }>`
  height: 60px;
  width: 100%;
  object-fit: cover;
  padding: 0 4rem;
  cursor: pointer;

  ${({ $isHovered }) =>
    $isHovered
      ? css`
          animation: ${scaleOutType3} 400ms ease-in forwards;
        `
      : css`
          animation: ${scaleInType3} 400ms ease-in forwards;
        `}
`;

export const CompanyLogoContainer = styled.div`
  width: 100%;
`;
