import { DateText, TopHeaderBody } from './styles';

import Icon from '@components/Icon';
import { RowAlignedItems } from '@components/LinearLayout';
import { getEventDetails } from '@store/slices/eventSlice';
import { counterDate } from '@utils/time';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

dayjs.extend(relativeTime);
dayjs.extend(duration);

const HeaderBottomComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const sluggedEvent = useSelector(getEventDetails)?.[0];

  const startDateCounter = useMemo(() => {
    return counterDate(sluggedEvent?.start_date);
  }, [sluggedEvent?.start_date]);

  return (
    <TopHeaderBody
      color={sluggedEvent?.design?.secondaryThemeColor}
      $isLeft={false}
    >
      <RowAlignedItems>
        {startDateCounter && (
          <RowAlignedItems $gap="0.5rem">
            <Icon
              indentLess
              name="event-pages_calendar"
              color={sluggedEvent?.design?.themeColor || theme.color.white}
            />
            <DateText>{`${t('still')} ${startDateCounter}`} </DateText>
          </RowAlignedItems>
        )}
      </RowAlignedItems>
    </TopHeaderBody>
  );
};

export default HeaderBottomComponent;
