import { Column, Row } from '@components/LinearLayout';
import { growDown, growUp } from '@theme/animations';
import styled, { css } from 'styled-components';

export const MainMenuItemRoot = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`;

export const MainMenuItemWrapper = styled.div<{
  $isSelected: boolean;
  $isMultiMenu: boolean;
  $backgroundColor: string;
  $isHovered: boolean | null;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 0;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  white-space: nowrap;
  height: 100%;
`;
export const MainMenuDropdown = styled(Row)`
  align-items: center;
`;

export const Title = styled.span<{ $textColor: string }>`
  color: ${({ theme }) => theme.color.mobilityGrey};
  font-size: ${({ theme }) => theme.fontSize.size18};
  font-weight: 700;

  &:active {
    cursor: pointer;
    color: ${({ $textColor }) => $textColor};
    transition: background-color 300ms ease-in;
    text-decoration: underline;
    text-decoration-thickness: 0.25rem;
    text-underline-offset: 0.7rem;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 0.25rem;
    text-underline-offset: 0.7rem;
  }
`;

export const IconWrapper = styled.div`
  margin: 0.2rem 0 0 0.5rem;
`;

export const SubMenuItems = styled(Column)<{ $isHovered: boolean | null }>`
  position: absolute;
  width: inherit;
  align-items: center;
  padding: 2rem 0 4rem 0;
  background-color: ${({ theme }) => theme.color.strokeGrey};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.radius20};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.radius20};
  gap: 1rem;
  ${({ $isHovered }) => {
    if ($isHovered === null) return 'display: none';
    if ($isHovered)
      return css`
        animation: ${growDown} 200ms ease-in forwards;
      `;
    else
      return css`
        animation: ${growUp} 200ms ease-in forwards;
      `;
  }}
`;
