import styled from 'styled-components';

export const SessionsRoot = styled.div`
  background-color: ${({ theme }) => theme.color.greyLight};
  padding: 10rem 12rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    padding: 6rem 1.5rem;
  }
`;

export const TitleText = styled.h2<{ color?: string }>`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.fontSize.size40};
  line-height: ${({ theme }) => theme.lineHeight.height130};

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    font-size: ${({ theme }) => theme.fontSize.size32};
  }
`;

export const Underline = styled.div<{
  color?: string;
}>`
  width: 160px;
  height: 0.4rem;
  background-color: ${({ color, theme }) => color || theme.color.white};
  margin-bottom: 1rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    width: 120px;
  }
`;
