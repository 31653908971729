import { ButtonRoot, ButtonTitle } from './styles';

import { useState } from 'react';

interface ButtonProps {
  icon?: React.ReactNode;
  title: string;
  backgroundColor?: string;
  textColor?: string;
  onClick?: () => void;
  borderRadius?: string;
  padding?: string;
  border?: string;
  width?: string;
  hoverBackgroundColor?: string;
  fontSize?: string;
  hoverTextColor?: string;
  lineHeight?: string;
  disabled?: boolean;
  testId?: string;
}

const RegularButton = ({
  icon,
  title,
  onClick,
  border,
  backgroundColor = 'grey',
  textColor,
  borderRadius,
  padding,
  width,
  hoverBackgroundColor,
  fontSize,
  hoverTextColor,
  lineHeight,
  disabled = false,
  testId = 'regular-button',
}: ButtonProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <ButtonRoot
      onClick={onClick}
      $borderRadius={borderRadius}
      $padding={padding}
      $border={border}
      $backgroundColor={backgroundColor}
      $width={width}
      $hoverBackgroundColor={hoverBackgroundColor}
      $disabled={disabled}
      data-testid={testId}
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false);
      }}
    >
      <ButtonTitle
        $textColor={textColor}
        $hoverTextColor={hoverTextColor}
        $fontSize={fontSize}
        $isHovered={isHovered}
        $lineHeight={lineHeight}
      >
        {icon || null}
        {title}
      </ButtonTitle>
    </ButtonRoot>
  );
};

export default RegularButton;
