import { HamburgerMenuRoot, Line1, Line2, Line3 } from './styles';

import { useDispatch } from 'react-redux';
import { setIsMenuOpen } from '../../store/slices/configSlice';

interface HamburgerMenuProps {
  isMenuOpen: boolean;
  strokeColor?: string;
  backgroundColor?: string;
}
const HamburgerMenu = ({
  isMenuOpen,
  strokeColor,
  backgroundColor,
}: HamburgerMenuProps) => {
  const dispatch = useDispatch();

  return (
    <HamburgerMenuRoot
      aria-label="Main Menu"
      onClick={() => dispatch(setIsMenuOpen(!isMenuOpen))}
      backgroundColor={backgroundColor}
    >
      <svg width="40" height="40" viewBox="0 0 100 100">
        <Line1
          strokeColor={strokeColor}
          $isOpen={isMenuOpen}
          d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
        />
        <Line2
          strokeColor={strokeColor}
          $isOpen={isMenuOpen}
          d="M 20,50 H 80"
        />
        <Line3
          strokeColor={strokeColor}
          $isOpen={isMenuOpen}
          d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
        />
      </svg>
    </HamburgerMenuRoot>
  );
};

export default HamburgerMenu;
