import styled from 'styled-components';

export const SectionContainer = styled.div<{
  backgroundImage: string;
  backgroundImageZoom?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 5rem 1rem;

  background-image: url(${props => props?.backgroundImage});
  background-size: ${({ backgroundImageZoom }) =>
    backgroundImageZoom || 'cover'};
  background-position: ${({ backgroundImageZoom }) =>
    backgroundImageZoom ? 'top' : 'center'};
  background-repeat: no-repeat;
  height: 650px;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    background-size: cover;
  }
`;

export const Description = styled.div<{ color?: string }>`
  color: ${({ color, theme }) => color || theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.size22};
  font-weight: 400;
  line-height: ${({ theme }) => theme.lineHeight.height150};

  margin-top: 3rem;
  margin-bottom: 5rem;
  text-align: center;
  max-width: 500px;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    font-size: ${({ theme }) => theme.fontSize.size18};
    margin-top: 2rem;
    margin-bottom: 3.5rem;
    width: 85%;
  }
`;

export const TextWrapper = styled.p<{ color?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1100px;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const TitleText = styled.h2<{ color: string }>`
  color: ${({ color, theme }) => color || theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.size40};
  line-height: ${({ theme }) => theme.lineHeight.height150};
  padding-bottom: 2rem;
  text-align: center;
  width: 80%;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    font-size: ${({ theme }) => theme.fontSize.size32};
    width: 100%;
  }
`;

export const UnderlineContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Underline = styled.div<{
  color?: string;
}>`
  width: 160px;
  height: 0.4rem;
  background-color: ${({ color, theme }) => color || theme.color.white};
`;
