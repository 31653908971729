import { BASE_MEDIA_URL } from './apiHooks';
import { KeyTopicItem } from '@composites/PartnerProspectusPdfContent/components/KeyTopics';
import dayjs from 'dayjs';

export const getImageSrc = (input?: string): string => {
  if (typeof input === undefined || input === null || !input?.length) {
    return '';
  }
  if (input?.length && input?.includes('http')) {
    return input;
  }
  const newLink = input
    ?.split('users')
    ?.join('profiles')
    ?.split('/profiles/')
    .join('-');
  return `${BASE_MEDIA_URL}/${newLink}`;
};

export const getFullLinkedInHttpLink = (link?: string): string => {
  if (!link) {
    return '';
  }
  if (link?.includes('linkedin')) {
    return link;
  } else {
    return 'https://www.linkedin.com/in/' + link;
  }
};

export const getFullTwitterHttpLink = (link?: string): string => {
  if (!link) {
    return '';
  }

  if (link?.includes('twitter')) {
    return link;
  } else {
    return 'https://twitter.com/' + link;
  }
};

export const getFullInstagramHttpLink = (link?: string): string => {
  if (!link) {
    return '';
  }

  if (link?.includes('instagram')) {
    return link;
  } else {
    return 'https://instagram.com/' + link;
  }
};

export const getFullXingHttpLink = (link?: string): string => {
  if (!link) {
    return '';
  }
  if (link?.includes('xing')) {
    return link;
  } else {
    return 'https://www.xing.com/profile/' + link;
  }
};

export const getFullFacebookHttpLink = (link?: string): string => {
  if (!link) {
    return '';
  }
  if (link?.includes('facebook')) {
    return link;
  } else {
    return 'https://www.facebook.com/' + link;
  }
};

export const formatWebsiteLink = (website?: string) => {
  if (!website) {
    return '';
  }
  if (website?.length > 0) {
    if (!website.startsWith('https://') && !website.startsWith('http://'))
      return `https://${website}`;
    else return website;
  }
  return '';
};

export const extractSearchParams = (searchParams: URLSearchParams) => {
  let searchObj = {};
  searchParams.forEach((value, key) => {
    searchObj = { ...searchObj, [key]: value };
  });
  return searchObj;
};

export const capitalizeFirstLetter = (S: string) => {
  if (S?.length === 0) return '';
  return S?.charAt(0).toUpperCase() + S?.slice(1);
};

export const generateFilterOptions = (options: string[]) => {
  return [...new Set(options)]?.map(option => ({
    key: option,
    value: option,
  }));
};

export interface PrerecordedVideo {
  image: string;
  videoReference: string;
  videoType: string;
}

export const getEmbedVimeo = (url: string) => {
  if (
    url?.includes('/embedparameter/') ||
    url?.endsWith('/embed') ||
    url?.includes('?h=') ||
    url?.includes('player.vimeo.com')
  )
    return url;
  else if (url?.includes('/event/')) return `${url}/embed`;
  else if (url?.includes('vimeo.com')) {
    const urlPathname = new URL(url)?.pathname
      ?.split('/')
      .filter(e => e?.length);
    if (urlPathname?.length === 1)
      return `https://player.vimeo.com/video/${urlPathname?.[0]}?embedparameter=${urlPathname?.[0]}`;
    if (urlPathname?.length === 2)
      return `https://player.vimeo.com/video/${urlPathname?.[0]}?h=${urlPathname?.[1]}`;
  }
  return `https://player.vimeo.com/video/${url}?embedparameter=${url}`;
};

export const getPrerecordedVideoUrl = (prerecorded: PrerecordedVideo) => {
  if (prerecorded?.videoType === 'youtube') {
    if (prerecorded.videoReference?.includes('/embed/'))
      return prerecorded.videoReference;
    return `https://www.youtube.com/embed/${prerecorded.videoReference}`;
  }
  if (prerecorded?.videoType === 'vimeo')
    return getEmbedVimeo(prerecorded.videoReference);

  return prerecorded.videoReference;
};

export const applyAndToArray = (array: boolean[]): boolean => {
  return array.reduce((acc: boolean, curr: boolean) => acc && curr, true);
};

export const findNearestValue = (numbers: number[], target: number) => {
  return numbers.reduce((nearest, num) =>
    Math.abs(num - target) < Math.abs(nearest - target) ? num : nearest
  );
};

export const getSpeakerFullName = (
  firstName: string | undefined,
  lastName: string | undefined
) => [firstName, lastName]?.filter(e => e?.length).join(' ');

export const groupAndSortByDate = <
  T extends Record<K, string>,
  K extends keyof T,
>(
  array: T[],
  key: K
): Record<string, T[]> => {
  const grouped = array.reduce<Record<string, T[]>>((result, currentValue) => {
    const groupKey = dayjs(currentValue[key]).format('DD-MM-YYYY');

    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(currentValue);

    return result;
  }, {});

  for (const groupKey in grouped) {
    grouped[groupKey].sort((a, b) => {
      const dateA = dayjs(a[key]);
      const dateB = dayjs(b[key]);

      if (dateA.isBefore(dateB)) {
        return -1;
      } else if (dateA.isAfter(dateB)) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return grouped;
};

export const getUrl = (url: string) => url.substring(1);

export function uniqBy<T extends Record<string, any>>(
  array: T[],
  key: keyof T
): T[] {
  const seen = new Map<T[keyof T], T>();
  array.forEach(item => {
    if (!seen.has(item[key])) {
      seen.set(item[key], item);
    }
  });
  return Array.from(seen.values());
}

export const safeJSONParse = (value: string | null | undefined) => {
  try {
    return JSON.parse(value ?? '{}');
  } catch (error) {
    return {};
  }
};

export const absoluteFilePath = (relativePath: string) => {
  const strippedPath = relativePath.split('/').slice(1).join('/');
  return `https://media.we-conect.com/${strippedPath}`;
};

export const getYoutubeVideoId = (url: string) => {
  const regex =
    /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

export const truncateAfterLastBrace = (input: string): string => {
  const lastBraceIndex = input.lastIndexOf('}');
  if (lastBraceIndex === -1) {
    return input;
  }
  return input.substring(0, lastBraceIndex + 1);
};

export const splitArrayBySubtitleLengthFunctional = (
  array: KeyTopicItem[],
  maxLength: number
): KeyTopicItem[][] => {
  return array.reduce(
    (acc: KeyTopicItem[][], item) => {
      const currentChunk = acc[acc.length - 1];
      const currentLength = currentChunk.reduce(
        (sum, i: KeyTopicItem) => sum + Number(i.subtitle.length) + 100,
        0
      );

      if (currentLength + item.subtitle.length > maxLength) {
        acc.push([item]);
      } else {
        currentChunk.push(item);
      }
      return acc;
    },
    [[]]
  );
};
