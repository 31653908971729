import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BackIcon,
  BottomContent,
  BottomDetailsContent,
  ContentWrapper,
  EventMenuRoot,
  LogoContainer,
  LogoImage,
  NextEventWrapper,
  SubMenuItems,
  TopContent,
  menuAnimationDuration,
} from './styles';

import weLogo from '@assets/images/weCONECT_col_RGB.svg';
import Icon from '@components/Icon';
import { mappingLinks } from '@composites/EventMenuDesktop';
import HamburgerMenu from '@composites/HamburgerMenu';
import MainMenuItemMobile from '@composites/MainMenuItemMobile';
import {
  AddressText,
  TopHeaderMenuRoot,
} from '@composites/TopHeaderMenu/styles';
import { adminEventsRouteFormat } from '@constants/paths';
import { MenuItem as MenuItemType } from '@hubs101/booking-api-skd-client/lib/types';
import { getLanguage, getMenuState } from '@store/slices/configSlice';
import { getEventDetails } from '@store/slices/eventSlice';
import { getImageSrc } from '@utils/fn';
import { useDesktopWidth } from '@utils/hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { eventTimeFormat } from '@utils/time';
import Skeleton from 'react-loading-skeleton';
import WeMenuItem from '@composites/WeMenuItem';
import { usePublicCoLocatedEventsByEventId } from '@utils/apiHooks';

const EventMenuMobile = ({
  TopHeaderComponentTop,
  TopHeaderComponentBottom,
}: {
  TopHeaderComponentTop?: JSX.Element;
  TopHeaderComponentBottom?: JSX.Element;
}) => {
  const { t } = useTranslation();
  const sluggedEvent = useSelector(getEventDetails)?.[0];
  const isMenuOpen = useSelector(getMenuState);
  const isDesktop = useDesktopWidth();
  const navigate = useNavigate();
  const theme = useTheme();
  const language = useSelector(getLanguage);

  const { data: coLocatedEvents, isLoading: isCoLocatedEventsLoading } =
    usePublicCoLocatedEventsByEventId({
      eventId: sluggedEvent?.id,
      enabled: sluggedEvent?.id?.length > 0 && sluggedEvent?.colocated,
    });

  const [selectedSubMenuItems, setSelectedSubMenuItems] = useState<
    Array<{
      id: string;
      title: string;
      subTitle: string;
      link: string;
      order: number;
    }>
  >([]);

  const sortedMenuItems = useMemo(
    () =>
      sluggedEvent?.website_menu
        ?.find(e => e.type === 'mainmenu')
        ?.items?.toSorted(
          (a: MenuItemType, b: MenuItemType) => a.order - b.order
        ),
    [sluggedEvent?.website_menu]
  );

  const link = useCallback(
    (item: MenuItemType) => {
      switch (item.type) {
        case 'internal':
          return `/${item.url}`;
        case 'external':
          return item.url;
        default:
          return t(mappingLinks[item.type as keyof typeof mappingLinks]);
      }
    },
    [t]
  );

  const mainMenuItems = useMemo(
    () =>
      sortedMenuItems
        ?.filter(e => !e.level)
        ?.map(item => {
          return {
            id: item.label,
            title: item.label,
            subTitle: item.label,
            link: link(item),
            order: item.order,
          };
        }),
    [link, sortedMenuItems]
  );

  const secondaryMenuItems = useMemo(
    () =>
      sortedMenuItems
        ?.filter(e => e.level)
        ?.map(item => {
          return {
            id: item.label,
            title: item.label,
            subTitle: item.label,
            link: link(item),
            order: item.order,
            index: mainMenuItems?.findLastIndex(
              element => element.order < item.order
            ),
          };
        }),
    [link, mainMenuItems, sortedMenuItems]
  );

  const maxItems = useMemo(() => {
    if (Number(selectedSubMenuItems?.length) > 0) {
      return Number(selectedSubMenuItems?.length + 1);
    } else return 100;
  }, [selectedSubMenuItems?.length]);

  useEffect(() => {
    if (!isMenuOpen) {
      setTimeout(() => {
        setSelectedSubMenuItems([]);
      }, menuAnimationDuration);
    }
  }, [isMenuOpen]);

  if (!isDesktop) return null;

  return (
    <EventMenuRoot>
      <TopContent>
        <LogoContainer $size={4}>
          <LogoImage
            src={
              sluggedEvent?.design?.eventLogoPosSvg?.length > 0
                ? getImageSrc(sluggedEvent?.design?.eventLogoPosSvg)
                : weLogo
            }
            alt={
              sluggedEvent?.name?.length ? sluggedEvent?.name : 'WeConect Logo'
            }
            $height={`${4}rem`}
          />
        </LogoContainer>
        <HamburgerMenu isMenuOpen={isMenuOpen} />
      </TopContent>

      <TopHeaderMenuRoot $color={sluggedEvent?.design?.themeColor ?? ''}>
        {TopHeaderComponentTop}
      </TopHeaderMenuRoot>

      <BottomContent $isOpen={isMenuOpen}>
        <ContentWrapper>
          <NextEventWrapper backgroundColor={sluggedEvent?.design?.themeColor}>
            <AddressText padding="0rem">
              {`${eventTimeFormat(
                sluggedEvent?.start_date ?? '',
                sluggedEvent?.end_date ?? '',
                language
              )}`}
            </AddressText>

            <div>{sluggedEvent?.location?.name || ''}</div>
          </NextEventWrapper>

          {sluggedEvent?.colocated && (
            <>
              {isCoLocatedEventsLoading ? (
                <Skeleton
                  count={1}
                  height="100%"
                  width="15rem"
                  enableAnimation
                  baseColor={sluggedEvent?.design?.themeColor}
                />
              ) : (
                <WeMenuItem
                  title={t('co-located-events')}
                  coLocatedEvents={coLocatedEvents}
                  backgroundColor={sluggedEvent?.design?.themeColor}
                  hoverBackgroundColor={theme.color.strokeGrey}
                />
              )}
            </>
          )}

          {mainMenuItems?.slice(0, maxItems)?.map((item, index) => (
            <MainMenuItemMobile
              key={item.id}
              title={item.title}
              link={item.link}
              subMenuItems={secondaryMenuItems?.filter(
                item => item.index === index
              )}
              onClick={() => {
                secondaryMenuItems !== null &&
                  setSelectedSubMenuItems(
                    secondaryMenuItems?.filter(item => item.index === index) ??
                      []
                  );
              }}
            />
          ))}

          <BottomDetailsContent
            $open={selectedSubMenuItems?.length > 0}
            $backgroundColor={sluggedEvent?.design?.secondaryThemeColor}
          >
            <BackIcon
              onClick={() => {
                setSelectedSubMenuItems([]);
              }}
            >
              <Icon
                name="caret_arrow_left"
                color={theme.color.white}
                $hoverColor={sluggedEvent?.design.themeColor}
                indentLess
                size={40}
              />
            </BackIcon>
            {Number(selectedSubMenuItems?.length) > 0 && (
              <SubMenuItems>
                {selectedSubMenuItems?.map(menuItem => (
                  <MainMenuItemMobile
                    key={menuItem.id}
                    title={menuItem.title}
                    link={menuItem.link}
                    onClick={() => {
                      navigate(
                        adminEventsRouteFormat(
                          sluggedEvent?.slug,
                          menuItem.link
                        )
                      );
                    }}
                    subMenuItems={[]}
                  />
                ))}
              </SubMenuItems>
            )}
          </BottomDetailsContent>
        </ContentWrapper>

        <div>{TopHeaderComponentBottom}</div>
      </BottomContent>
    </EventMenuRoot>
  );
};

export default EventMenuMobile;
