import {
  Description,
  TextWrapper,
  SectionContainer,
  TitleText,
  TitleWrapper,
  Underline,
  UnderlineContainer,
} from './styles';
import heroSectionDefaultBackgroundImage from '@assets/images/hero-section-default.png';
import Button from '@components/Button';
import { CenterItem } from '@components/LinearLayout';
import { getEventDetails } from '@store/slices/eventSlice';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

interface HeroSectionProps {
  title: string;
  description: string;
  backgroundImage?: string;
  backgroundImageZoom?: string;
  buttonText?: string;
  buttonURL?: string;
  textColor?: string;
}

const HeroSection = ({
  title,
  description,
  backgroundImage = heroSectionDefaultBackgroundImage,
  backgroundImageZoom = '',
  buttonText,
  buttonURL,
  textColor,
}: HeroSectionProps) => {
  const sluggedEvent = useSelector(getEventDetails)?.[0];
  const { design } = sluggedEvent || { design: {} };
  const theme = useTheme();

  return (
    <SectionContainer
      backgroundImage={backgroundImage}
      backgroundImageZoom={backgroundImageZoom}
    >
      <TextWrapper>
        <TitleWrapper>
          <TitleText color={textColor || theme.color.white}>{title}</TitleText>
        </TitleWrapper>

        <UnderlineContainer>
          <Underline color={design.themeColor} />
        </UnderlineContainer>

        <Description color={textColor || theme.color.white}>
          {description}
        </Description>

        {buttonText && (
          <CenterItem>
            <Button
              title={buttonText}
              backgroundColor={sluggedEvent?.design?.themeColor}
              hoverBackgroundColor={theme.color.mobilityGrey}
              textColor={theme.color.white}
              borderRadius={theme.borderRadius.radius10}
              padding="1.2rem 5.5rem"
              width="max-content"
              onClick={() => {
                window.open(
                  `${buttonURL ?? ''}`,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            />
          </CenterItem>
        )}
      </TextWrapper>
    </SectionContainer>
  );
};

export default HeroSection;
