import { LANGUAGE, LANGUAGE_TYPE } from './constants';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import i18n from '../i18n';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(duration);

export const eventTimeFormat = (
  startDate: string,
  endDate: string,
  language: (typeof LANGUAGE_TYPE)[number]
) => {
  const startYear = dayjs(startDate).format('YYYY');
  const endYear = dayjs(endDate).format('YYYY');
  const startMonth =
    language === LANGUAGE.ENGLISH
      ? dayjs(startDate).format('MMMM')
      : dayjs(startDate).format('MMMM').split('.').join('');
  const endMonth =
    language === LANGUAGE.ENGLISH
      ? dayjs(endDate).format('MMMM')
      : dayjs(endDate).format('MMMM').split('.').join('');
  const startDay = dayjs(startDate).format('DD');
  const endDay = dayjs(endDate).format('DD');

  if (language === LANGUAGE.ENGLISH) {
    if (startYear !== endYear)
      return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
    else if (startMonth === endMonth) {
      if (startDay === endDay) return `${endMonth} ${endDay}, ${endYear}`;
      else return `${endMonth} ${startDay} - ${endDay}, ${endYear}`;
    } else
      return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${endYear}`;
  } else {
    if (startYear !== endYear)
      return `${startMonth} ${startDay},  ${startYear} - ${endMonth} ${endDay},  ${endYear}`;
    else if (startMonth === endMonth) {
      if (startDay === endDay) return `${endMonth} ${startDay},  ${endYear}`;
      else return `${endMonth} ${startDay} - ${endDay}, ${endYear}`;
    } else
      return `${startMonth} ${startDay} - ${endMonth} ${endDay},  ${endYear}`;
  }
};

export const isFutureDate = (date: string) => {
  return dayjs(date).isAfter(dayjs());
};
export const findClosestDate = (dates: string[]) => {
  const now = new Date();

  const closestDate = dates.reduce((a, b) => {
    const aDate = new Date(a);
    const bDate = new Date(b);
    return Math.abs(aDate.getTime() - now.getTime()) <
      Math.abs(bDate.getTime() - now.getTime())
      ? a
      : b;
  }, '');

  return closestDate;
};

export const formatDuration = (duration: number) => {
  if (duration) {
    const hours = Math.floor(duration / 3600);
    if (hours > 0)
      return new Date(duration * 1000).toISOString().substring(11, 19);
    return new Date(duration * 1000).toISOString().substring(14, 19);
  }
  return '00:00';
};

export const getUniqueDays = (dates: string[]) => {
  return [
    ...new Set(
      dates
        ?.map(e => dayjs(e).unix())
        .sort((a, b) => a - b)
        ?.map(e => dayjs.unix(e).format('MM-DD-YYYY'))
    ),
  ];
};

export const getUniquePdfDays = (dates: string[]) => {
  return [
    ...new Set(
      dates
        ?.map(e => dayjs(e).unix())
        .sort((a, b) => a - b)
        ?.map(e => dayjs.unix(e).format('dddd, MMM DD'))
    ),
  ];
};

export const counterDate = (date: string) => {
  const isPastDate = dayjs(date).isBefore(dayjs());
  if (!date || isPastDate) return null;
  const duration = dayjs.duration(dayjs(date).diff(dayjs()));

  if (duration?.days() > 0) {
    const quantity = duration?.days() === 1 ? i18n.t('day') : i18n.t('days');
    return `${duration.asDays().toFixed(0)} ${quantity}`;
  } else if (duration?.hours() > 0) {
    const quantity = duration?.hours() === 1 ? i18n.t('hour') : i18n.t('hours');
    return `${duration.hours()} ${quantity}`;
  } else if (duration?.minutes() > 0) {
    const quantity =
      duration?.minutes() === 1 ? i18n.t('minute') : i18n.t('minutes');
    return `${duration.minutes()} ${quantity}`;
  }
};

export const formatDate = (date: string) => {
  const dateItems = date?.split('-');
  return `${dateItems[2]}-${dateItems[0]}-${dateItems[1]}`;
};

export const formatDateToICS = (date: string) => {
  return dayjs(date).format('YYYYMMDDTHHMMss') + 'Z';
};
